<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="设备名称">
            <a-input v-model="form.others.name" />
          </a-form-model-item>
          <a-form-model-item prop="number" label="设备编号">
            <a-input v-model="form.others.number" />
          </a-form-model-item>
          <a-form-model-item prop="use_part" label="使用部门">
            <a-input v-model="form.others.use_part" />
          </a-form-model-item>
          <a-form-model-item prop="storage_location" label="存放地点">
            <a-textarea v-model="form.others.storage_location" />
          </a-form-model-item>
          <a-form-model-item prop="utilize" label="使用环节">
            <a-select v-model="form.others.utilize" style="width: 100%">
              <a-select-option value="生产">
                生产
              </a-select-option>
              <a-select-option value="包装">
                包装
              </a-select-option>
              <a-select-option value="验证">
                验证
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="material_quality" label="材质">
            <a-input v-model="form.others.material_quality" />
          </a-form-model-item>
          <a-form-model-item prop="describe" label="设备描述">
            <a-textarea v-model="form.others.describe" />
          </a-form-model-item>
          <a-form-model-item prop="produce_time" label="采购日期">
            <a-date-picker v-model="form.others.produce_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="install_time" label="安装日期">
            <a-date-picker v-model="form.others.install_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="负责人">
            <a-input v-model="form.others.contact" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="手机号">
            <a-input v-model="form.others.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.others.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input v-model="form.others.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.others.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { deviceManageAdd, deviceManageEdit } from "@/api/produce";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
          { max: 64, message: "超出最大长度 (64)", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
          { max: 32, message: "超出最大长度 (32)", trigger: "blur" },
        ]
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? deviceManageEdit : deviceManageAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      if(!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
